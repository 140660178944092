<template>
  <div class="sort-arrow">
    <span class="arrow-up" :style="{ width: fontSize, height: fontSize, marginBottom: props.margin }" :class="sortType === 'ASC' ? 'arrow-up--current' : ''"></span>
    <span class="arrow-down" :style="{ width: fontSize, height: fontSize }" :class="sortType === 'DESC' ? 'arrow-down--current' : ''"></span>
  </div>
</template>

<script setup>
const props = defineProps({
  sortType: {
    type: String,
    default: ''
  },
  fontSize: {
    type: Number,
    default: 0
  },
  margin: {
    type: Number,
    default: 0
  }
})
const sortType = computed(() => {
  return props.sortType
})
</script>

<style lang="scss" scoped>
.sort-arrow {
  margin: -1px 0;
  cursor: pointer;

  .arrow-up,
  .arrow-down {
    display: block;
    width: 0;
    height: 0;
    margin: 1px 0;
  }

  .arrow-up {
    border: 5px solid transparent;
    border-bottom-color: #979cae;

    &--current {
      border-bottom-color: #f87600;
    }
  }

  .arrow-down {
    border: 5px solid transparent;
    border-top-color: #979cae;

    &--current {
      border-top-color: #f87600;
    }
  }
}
</style>
